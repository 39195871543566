import React from 'react'
import styled from 'styled-components'
import { modularScale } from 'polished'

const Wrapper = styled.section`
  margin: 0 auto auto;
  width: 100%;
  max-width: ${props => props.theme.layout.maxWidth};
  padding: 3em 1.5em 2em;
  flex-grow: 1;

  h1 {
    margin-bottom: ${modularScale(0)};
  }

  a {
    color: ${({ theme }) => theme.color.red} !important;
  }
`

const Container = props => {
  return <Wrapper>{props.children}</Wrapper>
}

export default Container
